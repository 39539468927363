@font-face {
    font-family: "Avenir-Black";
    src: url("../../assets/fonts/Avenir-Black.otf");
}

@font-face {
    font-family: "Avenir-Book";
    src: url("../../assets/fonts/Avenir-Book.otf");
}

@font-face {
    font-family: "Avenir-Roman";
    src: url("../../assets/fonts/Avenir-Roman.otf");
}

@font-face {
    font-family: "LeGrandBold-Regular";
    src: url("../../assets/fonts/LeGrandBold-Regular.otf");
}

@font-face {
    font-family: "LeGrandBold-Regular";
    src: url("../../assets/fonts/LeGrandCapital-Regular.otf");
}

@font-face {
    font-family: "LeGrandCapital-Regular";
    src: url("../../assets/fonts/LeGrandCapital-Regular.otf");
}

@font-face {
    font-family: "LeGrandItalic-Regular";
    src: url("../../assets/fonts/LeGrandItalic-Regular.otf");
}

@font-face {
    font-family: "LeGrandLigatures-Regular";
    src: url("../../assets/fonts/LeGrandLigatures-Regular.otf");
}

@font-face {
    font-family: "LeGrandRegular-Regular";
    src: url("../../assets/fonts/LeGrandRegular-Regular.otf");
}

@font-face {
    font-family: "Saudagar";
    src: url("../../assets/fonts/Saudagar.ttf");
}

.heading_1 {
    font-family: LeGrandRegular-Regular;
    font-size: 5rem;
    color: $color-white;
    font-weight: unset;
    text-align: center;
    @include respond-below(sm) {
        font-size: 3.5rem;
    }
    &.white {
        color: #fff;
    }
    &.black {
        color: $color_black;
    }
}

.p_content {
    font-size: 1.7rem;
    font-family: Avenir-Book;
    color: #707070;
    font-weight: unset;
    width: 50vw;
    text-align: center;
    margin: 2rem 0;
    &.white {
        color: #fff;
    }
    &.black {
        color: #000;
    }
}

.btn_primary {
    color: #fff;
    border: none;
    font-family: LeGrandRegular-Regular;
    width: fit-content;
    font-size: 2rem;
    border-radius: .5rem;
    display: flex;
    text-align: center;
}