@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/grid';
@import 'abstracts/mixins';
@import 'base/animations';
@import 'base/base';
@import 'base/typography';
@import 'base/utilities';
@import 'base/grid';
@import 'components/buttons';
@import 'components/form';
@import 'layout/footer';
@import 'layout/header';
// @import 'pages/';
@import 'pages/landing';
@import 'pages/about_us';
@import 'pages/contact_us';
@import 'pages/urban_lux';
@import 'pages/services';
@import 'pages/booking';
@import 'pages/entry_landing';
@import "~react-image-gallery/styles/scss/image-gallery.scss";
.image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: calc(100vh - 25vh) !important;
}

.image-gallery-slide .image-gallery-image {
    object-fit: cover !important;
}

.image-gallery {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: inherit;
}