.mood_header {
    width: 100vw;
    position: fixed;
    top: 0;
    z-index: 100;
    &_menu {
        width: inherit;
        height: 8rem;
        &_wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: inherit;
            justify-content: space-between;
            background-image: linear-gradient(to right, #00000000, #4722118f);
            &_logo {
                height: inherit;
                display: flex;
                align-items: center;
                margin-left: 5%;
                & img {
                    width: 10rem;
                }
            }
            &_service {
                font-size: 2rem;
                text-transform: uppercase;
                font-family: Baskerville;
                display: flex;
                align-items: center;
                justify-content: center;
                column-gap: 1rem;
                cursor: pointer;
                &_span {
                    color: #fff;
                }
                &_btn {
                    border: none;
                    background-color: unset;
                    color: $color_base_theme;
                    font-family: Saudagar;
                    font-size: 3.5rem;
                    margin-top: -0.15rem;
                    cursor: pointer;
                }
            }
            &_menu {
                display: flex;
                align-items: center;
                justify-content: center;
                column-gap: 1rem;
                margin-right: 4%;
                cursor: pointer;
                &_span {
                    font-size: 2rem;
                    font-family: Baskerville;
                    color: #fff;
                    text-transform: uppercase;
                }
                &_btn {
                    border: none;
                    background-color: unset;
                    outline: none;
                    cursor: pointer;
                    color: $color_base_theme;
                    & svg {
                        height: 3rem;
                        width: 3rem;
                        fill: currentColor;
                    }
                    &.close {
                        z-index: 4;
                        color: $color_base_theme;
                        font-family: Saudagar;
                        font-size: 5.5rem;
                    }
                    &.header_services {
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }
    }
}

.mood_header_links {
    position: fixed;
    z-index: 2;
    background-color: #1d1d1dfa;
    width: 100vw;
    height: 100vh;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    &_nav {
        display: flex;
        &_ul {
            display: flex;
            list-style: none;
            column-gap: 2.5rem;
            @include respond-below(sm) {
                flex-direction: column;
                align-items: center;
            }
            &_li,
            a {
                color: #fff;
                list-style: none;
                font-family: LeGrandRegular-Regular;
                font-size: 5.5rem;
                cursor: pointer;
                text-decoration: none;
                &.active {
                    color: $color_base_theme;
                    // pointer-events: none;
                    text-decoration: underline;
                }
            }
        }
    }
} //
.men {
    & .mood_header {
        &_menu {
            width: inherit;
            height: 8rem;
            &_wrapper {
                background-image: linear-gradient(to right, rgb(0 0 0 / 0%), rgb(37 21 14 / 81%));
                &_logo {
                    & img {}
                }
            }
        }
    }
}