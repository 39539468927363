.footer {
    background-color: #000;
    height: 33vh;
    @include respond-below(sm) {
        height: auto;
    }
    &_wrapper {
        height: 30vh;
        background-color: #707070;
        padding: 0 10vw;
        padding-top: 2rem;
        // @media (min-width: 767px) and (max-height: 620px) {
        //     padding-top: unset;
        // }
        @include respond-below(sm) {
            height: auto;
            padding: 0 4vw;
            padding-top: 3rem;
            width: 100%;
        }
        &_container {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            column-gap: 5rem;
            @include respond-below(sm) {
                grid-template-columns: 1fr 1fr;
                column-gap: 3rem;
                row-gap: 2rem;
                place-content: center;
                padding: 1rem 0rem 4rem 0;
            }
            &_cont {
                display: flex;
                flex-direction: column;
                color: #fff;
                padding: 0 1rem;
                &_name {
                    font-family: LeGrandRegular-Regular;
                    font-size: 3.5rem;
                    text-transform: uppercase;
                    & span {
                        &:first-child {
                            margin-left: 0rem;
                        }
                        &:last-child {
                            margin-left: -1.5rem;
                        }
                    }
                }
                &_services_ul {
                    display: flex;
                    flex-direction: row;
                    column-gap: 1rem;
                    margin: unset !important;
                    padding: unset !important;
                    list-style: none;
                    margin-top: -1rem !important;
                    margin-left: 0.6rem !important;
                    &_li {
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: start;
                        font-family: Saudagar;
                        font-size: 1.2rem;
                        &::before {
                            background-color: #fff;
                            content: "";
                            height: 0.5rem;
                            width: 0.5rem;
                            position: absolute;
                            left: -.75rem;
                            border-radius: 50%;
                        }
                    }
                }
                &_p {
                    font-size: 1.6rem;
                    font-family: Avenir-Roman;
                    margin-top: 2rem;
                }
                &_address_icon {
                    margin-top: 1rem;
                    display: flex;
                    // justify-content: center;
                    & svg {
                        width: 3rem;
                        height: 3rem;
                        fill: #fff;
                    }
                }
                &_h6 {
                    font-family: Avenir-Roman;
                    font-size: 2rem;
                    font-weight: unset;
                    text-align: left;
                    border-bottom: 1px solid $color_base_theme;
                    width: fit-content;
                    margin-bottom: 1rem;
                    @media (min-width: 767px) and (max-height: 620px) {
                        margin-bottom: unset;
                    }
                }
                &_address {
                    &_ul {
                        display: flex;
                        flex-direction: column;
                        list-style: none;
                        &_li {
                            font-size: 1.3rem;
                            font-family: Avenir-Roman;
                        }
                    }
                }
            }
        }
    }
}