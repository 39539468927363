.services {
    height: 100vh;
    overflow-y: scroll;
    background-color: #f4f4f4;
    &_wrapper {
        width: inherit;
        background-color: #f4f4f4;
        &_services {
            &_ul {
                width: 60%;
                border: 1px solid;
                display: flex;
                flex-direction: row;
                list-style: none;
                justify-content: space-around;
                font-size: 1.5rem;
                margin-top: 3rem;
                &_li {
                    width: 100%;
                    border: 0.2px solid #79797a;
                    height: 100%;
                    padding: 0.4rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-top: unset;
                    border-bottom: unset;
                    font-family: 'Avenir-Roman';
                    &.a {
                        background-color: #000;
                        color: $color_base_theme;
                    }
                }
            }
        }
        &_header {
            height: 75vh;
            background-size: cover;
            background-position: center;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            row-gap: 2rem;
            background-image: linear-gradient(to right, #0000008d, #0000008d), url(../../assets/img/2Asset.svg);
            position: relative;
            &.service_desc {
                background-image: linear-gradient(to right, #0000008d, #0000008d), url(../../assets/img/ladies.png);
            }
            @include respond-below(sm) {
                height: 60vh;
            }
            &_subheadings {
                font-family: LeGrandRegular-Regular;
                font-size: 6rem;
                color: #fff;
                font-weight: normal;
                @include respond-below(sm) {
                    font-size: 4rem;
                }
            }
            &_btn {
                color: $color_base_theme;
                border: none;
                border-bottom: 1px solid $color_base_theme;
                font-family: LeGrandRegular-Regular;
                width: fit-content;
                font-size: 3rem;
                background-color: unset;
            }
        }
        // welcome
        &_welcome {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 1rem 0;
            background-color: #f4f4f4;
            &_heading {
                font-family: LeGrandBold-Regular;
                font-size: 5rem;
                color: $color_base_theme_dark;
                font-weight: unset;
            }
            & .liner {
                &:first-child {
                    margin-top: 5rem;
                }
                height: 0.2rem;
                width: 15vw;
                background-color: $color_base_theme;
                margin: 1.5rem 0;
                @include respond-below(sm) {
                    width: 70vw;
                }
            }
            &_p {
                font-size: 1.7rem;
                font-family: Avenir-Book;
                color: #707070;
                font-weight: unset;
                width: 50vw;
                text-align: center;
                margin: 2rem 0;
                @include respond-below(sm) {
                    width: 90vw;
                }
            }
        }
        // transformation
        &_transformation {
            display: flex;
            width: 100%;
            &_wrapper {
                width: inherit;
                margin-block: 4rem;
                &_heading {
                    text-align: center;
                    flex-direction: column;
                    display: flex;
                    width: -moz-fit-content;
                    margin: 0 auto;
                    font-family: LeGrandRegular-Regular;
                    font-size: 4.8rem;
                    color: $color_base_theme_dark;
                    font-weight: unset;
                    text-transform: capitalize !important;
                    & span {
                        margin-top: -1.7%;
                        margin-left: 12.9%;
                    }
                }
                &_services {
                    width: 100%;
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    place-content: center;
                    place-items: center;
                    @include respond-below(sm) {
                        display: flex;
                        flex-direction: column;
                    }
                    & .service {
                        width: 100%;
                        height: 70vh;
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        justify-content: center;
                        row-gap: 2rem;
                        background-size: cover;
                        background-position: top;
                        &.team {
                            background-image: url(../../assets/img/team.svg) !important;
                            border: 1px solid #ecae93;
                            border-start-end-radius: 22rem;
                            column-gap: 1rem;
                        }
                        @include respond-below(sm) {
                            height: 45vh;
                        }
                        &:first-child {
                            background-image: linear-gradient(to right, #00000046, #0000008d), url(../../assets/img/men.png);
                        }
                        &:nth-child(2) {
                            background-image: linear-gradient(to right, #00000046, #0000008d), url(../../assets/img/men.png);
                        }
                        &:nth-child(3) {
                            background-image: linear-gradient(to right, #00000046, #0000008d), url(../../assets/img/men.png);
                        }
                        &_heading {
                            font-family: LeGrandBold-Regular;
                            font-size: 4rem;
                            color: #fff;
                            font-weight: unset;
                            margin-top: 1rem;
                            margin-bottom: 2rem;
                        }
                        &_line {
                            height: 0.2rem;
                            width: 15vw;
                            background-color: $color_base_theme;
                            margin: 1.05rem 0;
                            @include respond-below(sm) {
                                width: 50vw;
                            }
                        }
                        &_btn {
                            color: $color_base_theme;
                            border: none;
                            font-family: LeGrandRegular-Regular;
                            width: fit-content;
                            font-size: 3rem;
                            background-color: unset;
                        }
                    }
                }
            }
        }
        // meet
        &_meet {
            width: inherit;
            &_wrapper {
                &_heading {
                    font-family: LeGrandRegular-Regular;
                    font-size: 5rem;
                    color: $color_base_theme_dark;
                    font-weight: unset;
                    text-align: center;
                    margin: 4rem 0;
                }
                & .member {
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    justify-content: center;
                    flex-direction: column;
                    margin: 3rem 0;
                    @include respond-below(sm) {
                        // align-items: start;
                        justify-content: start;
                    }
                    &_initials {
                        margin-top: -7rem;
                        text-align: left;
                        &_name {
                            font-family: LeGrandRegular-Regular;
                            font-size: 3.5rem;
                            color: #707070;
                        }
                        &_roles {
                            border-left: 1px solid #79797a;
                            display: flex;
                            flex-direction: column;
                            padding-left: 1rem;
                            row-gap: .5rem;
                            margin-top: 1rem;
                            &_role {
                                font-size: 1.6rem;
                                font-family: Avenir-Roman;
                                color: #707070;
                            }
                        }
                    }
                    &_desc {
                        font-size: 1.6rem;
                        font-family: Avenir-Roman;
                        color: #707070;
                        text-align: center;
                    }
                    & svg {
                        width: 5rem;
                        height: 5rem;
                        fill: #707070
                    }
                    & img {
                        height: 42vh;
                        width: fit-content;
                        @include respond-below(sm) {
                            height: 50vh;
                        }
                    }
                    &_wrapper {
                        width: 75vw;
                        display: grid;
                        grid-template-columns: 1fr 2fr 1fr;
                        margin: 0 auto;
                        @include respond-below(sm) {
                            grid-template-columns: 1fr;
                            width: 95vw;
                        }
                    }
                }
            }
        }
        &_banner {
            width: inherit;
            &_wrapper {
                width: 100%;
                background-image: linear-gradient(to bottom, #0000007c, #000000c7), url(../../assets/img/quote.png);
                height: 50vh;
                background-size: cover;
                background-position: top;
                display: flex;
                align-items: center;
                justify-content: center;
                row-gap: 3rem;
                flex-direction: column;
                @include respond-below(sm) {
                    height: 40vh;
                }
                & p {
                    position: relative;
                    &::after {
                        margin-left: 6rem;
                    }
                    &::before {
                        margin-left: -9rem;
                        @include respond-below(sm) {
                            margin-left: -14rem;
                        }
                    }
                    &::before,
                    &::after {
                        content: "";
                        background-color: #fff;
                        width: 6rem;
                        height: 0.1rem;
                        position: absolute;
                        bottom: 0;
                        top: 50%;
                        @include respond-below(sm) {
                            width: 8rem;
                        }
                    }
                }
            }
        }
        &_consult {
            width: inherit;
            &_wrapper {
                width: 100%;
                display: flex;
                margin: 10vh 0;
                @include respond-below(sm) {
                    display: flex;
                    margin: 6vh 0;
                    width: 100%;
                    flex-direction: column;
                }
                &_container {
                    width: 45%;
                    margin: 0 auto;
                    @include respond-below(sm) {
                        margin: 0 auto;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        row-gap: 2rem;
                    }
                    & .p_content {
                        width: 70%;
                        margin: 0 auto;
                        @include respond-below(sm) {
                            width: 90%;
                        }
                    }
                    & .consult {
                        display: flex;
                        flex-direction: column;
                        row-gap: 3rem;
                        justify-content: center;
                        width: 70%;
                        padding-top: 4rem;
                        @include respond-below(sm) {
                            width: 90%;
                        }
                        &_input {
                            border: none;
                            border-bottom: 2px solid #79797a;
                            font-size: 1.7rem;
                            font-family: Avenir-Book;
                            color: #707070;
                            padding: 1rem 0;
                            background-color: transparent;
                            outline: none;
                        }
                        &_btns {
                            display: flex;
                            flex-direction: row;
                            & .btn_primary {
                                background-color: #2A2824;
                                padding: 1rem 2.5rem;
                            }
                            & svg {
                                width: 5rem;
                                height: 5rem;
                                fill: #707070
                            }
                        }
                    }
                }
            }
        }
    }
    &.desc {
        .member_initials {
            margin-top: -7rem;
            text-align: left;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            row-gap: 1rem;
        }
    }
}

.charges_list {
    width: 100%;
}

.services_quote_list {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 1.4rem;
    list-style: none;
    &_l {
        font-size: 1.4rem;
        border-bottom: 2px solid #1d1c1c;
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 1rem 0;
        font-family: "Avenir-Roman";
        & span {
            &.price {
                color: #000;
                font-weight: Bold;
            }
            &:first-child {
                color: #79797a;
            }
        }
    }
    & .consult_btns {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 2rem;
        & .btn_primary {
            background-color: #000;
            padding: 1rem 4rem;
            margin: 2rem 0;
        }
    }
}

.base_container {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 4rem auto;
    & p {
        font-size: 1.4rem;
        width: 50%;
        text-align: center;
        font-family: "Avenir-Roman";
    }
}

.non_slider {
    position: relative;
    height: inherit;
    width: 100vw;
    background-image: linear-gradient(to right, #0000008d, #0000008d);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
    @include respond-below(sm) {
        height: 60vh!important;
    }
}

.services_wrapper_meet_wrapper .member_wrapper.video_prev {
    display: flex;
    width: 100vw;
}