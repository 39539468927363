*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    font-size: 62.5%;
    @include respond-below(xl) {
        font-size: 50.5%;
    }
}

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;
    line-height: 1.7;
    color: $color-black;
    padding: 0rem;
    box-sizing: border-box;
}

.zindex_4 {
    z-index: 4;
}

.book_now {
    position: fixed;
    z-index: 100;
    bottom: 5rem;
    right: 2rem;
    border-radius: 50%;
    height: 20rem;
    width: 20rem;
    border: none;
    background-color: $color_button_accent;
    color: $color-black;
    font-family: LeGrandBold-Regular;
    font-size: 4rem;
    font-weight: unset;
    & a {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        row-gap: 1.3rem;
        text-decoration: none;
        color: $color-black;
    }
}

button {
    cursor: pointer;
}


/* width */

::-webkit-scrollbar {
    width: 10px;
}


/* Track */

 ::-webkit-scrollbar-track {
    background: $color_base_theme;
}


/* Handle */

 ::-webkit-scrollbar-thumb {
    background: $color_button_accent;
    border-radius: 1rem;
}


/* Handle on hover */

 ::-webkit-scrollbar-thumb:hover {
    background: #672f9495;
}