.landing {
    height: 100vh;
    width: 100%;
    &_wrapper {
        height: inherit;
        width: inherit;
        background-color: #01010a65;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        // background-image: url(../../assets/img/4Asset.svg);
        // filter: blur(8px);
        // -webkit-filter: blur(8px);
        // height: 100%;
        &_svg {
            position: absolute;
            height: 100vh;
            width: 100vw;
            z-index: -1;
            overflow: hidden;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            & svg {
                // height: inherit;
                // width: inherit;
            }
        }
        &_heading {
            font-family: LeGrandRegular-Regular;
            font-size: 14.5rem;
            color: #fff;
            font-weight: normal;
        }
    }
}