    .booking {
        &_header {
            margin: 4rem 0;
            &_btn {
                margin: 0 3rem;
            }
        }
        &_wrapper {
            // margin-top: 7vh; `
            display: grid;
            grid-template-columns: 1fr 1fr;
            &_container {
                height: 100vh;
                & ._booking {
                    &_tab_container {
                        padding: 0 3rem;
                        & .react-tabs__tab-list {
                            border: unset;
                            display: flex;
                            flex-direction: row;
                            column-gap: 4rem;
                            width: 100%;
                            justify-content: space-around;
                            & li {
                                border: unset;
                                border-bottom: 2px solid gray;
                                width: inherit;
                                font-size: 2rem;
                                text-align: center;
                                font-family: Avenir-Book;
                            }
                        }
                    }
                }
                &._cart {
                    background-size: cover;
                    background-position: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    row-gap: 2rem;
                    background-image: linear-gradient(to right, #00000037, #00000039), url(../../assets/img/booking_banner.png);
                    position: relative;
                }
            }
        }
    }
    
    .people {
        margin-top: 4vh;
        height: 70vh;
        width: 80%;
        &_wrapper {
            position: relative;
            height: inherit;
            &_heading {
                font-family: LeGrandRegular-Regular;
                font-size: 5.5rem;
                color: $color_base_theme_dark;
                font-weight: unset;
            }
            &_p {
                font-size: 2rem;
                text-align: start;
                font-family: Avenir-Book;
            }
            &_input {
                margin-top: 4vh;
                width: 100%;
                &_wrapper {
                    height: 7.5vh;
                    display: grid;
                    grid-template-columns: 5fr 1fr 1fr 1fr;
                    box-shadow: 01px 1px 4px #79797a;
                    border-radius: 1rem;
                    place-content: center;
                    &_span {
                        border-right: 2px solid #79797a;
                        height: inherit;
                        border-right: 2px solid #79797a;
                        height: inherit;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 2rem;
                        font-family: Avenir-Book;
                        & button {
                            height: inherit;
                            width: -webkit-fill-available;
                            border: unset;
                            background: none;
                            font-size: inherit;
                            font-family: inherit;
                            cursor: pointer;
                        }
                        &:first-child {
                            justify-content: start;
                            padding-left: 5%;
                        }
                        &:last-child {
                            border: none;
                        }
                    }
                }
            }
        }
    }
    
    .button_next {
        position: absolute;
        bottom: 5%;
        width: 100%;
        border: none;
        background-color: $color-black;
        color: $color-white;
        padding: 2rem 0;
        border-radius: 1rem;
        cursor: pointer;
    }
    
    .date {
        position: relative;
        height: 70vh;
        & .people_wrapper {
            &_input {
                display: grid;
                grid-template-columns: 1fr 1fr;
                column-gap: 2rem;
                &_wrapper {
                    grid-template-columns: 1fr;
                    &_span {
                        border: none;
                        input[type="date"],
                        input[type="time"] {
                            width: 80%;
                            border: none;
                            &::-webkit-calendar-picker-indicator {
                                color: #fff;
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
    
    .extras {
        position: relative;
        height: 70vh;
        &_wrapper {
            display: flex;
            flex-direction: column;
            width: 100%;
            row-gap: 3rem;
            &_extra {
                display: flex;
                flex-direction: row;
                background-color: #f0ecec;
                box-shadow: 0px 2px 4px #79797a;
                max-height: 20vh;
                &_thumbnail {
                    & img {
                        height: inherit;
                        max-height: 20vh;
                    }
                }
                &_content {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    row-gap: 1rem;
                    padding-left: 5%;
                    &_p {
                        font-size: 1.6rem;
                        font-family: Avenir-Book;
                    }
                    &_h2 {
                        font-size: 2.6rem;
                        font-family: Saudagar;
                    }
                }
            }
        }
    }
    
    .checkout {
        position: relative;
        & .button_next {
            position: relative;
        }
    }