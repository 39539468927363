.landing_lux {
    height: 100vh;
    width: 100vw;
    &_wrapper {
        height: inherit;
        width: inherit;
        display: flex;
        position: relative;
        background-color: rgb(1 1 10 / 54%);
        &_img {
            position: absolute;
            height: inherit;
            width: inherit;
            z-index: -1;
            overflow: hidden;
            & svg {
                position: inherit;
                width: 100vw;
                left: 0;
                right: 0;
            }
        }
        &_container {
            width: 50%;
            height: 100vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            row-gap: 1.5rem;
            margin-left: 5%;
            &_heading {
                font-family: LeGrandRegular-Regular;
                font-size: 7.5rem;
                color: #fff;
                font-weight: normal;
            }
            &_p {
                font-family: LeGrandRegular-Regular;
                font-size: 2.5rem;
                color: #fff;
                font-weight: normal;
            }
            &_btn {
                color: $color_base_theme;
                border: none;
                border-bottom: 1px solid $color_base_theme;
                font-family: LeGrandRegular-Regular;
                width: fit-content;
                font-size: 3rem;
                background-color: unset;
            }
        }
    }
}